export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Quote Price',
        route: 'quote-price',
        icon: 'TagIcon',
      },
      {
        title: 'Order Analytic',
        route: 'order-analytic',
        icon: 'BarChartIcon',
      },
      {
        title: 'Order Log',
        route: 'order-log',
        icon: 'TrelloIcon',
      },
    ],
  },
  {
    title: 'Outlet Order',
    route: 'Outlet-order',
    icon: 'BriefcaseIcon',
    children: [
      {
        title: 'Create Order',
        route: 'Outlet-order',
        icon: 'BoxIcon',
      },
      {
        title: 'Incoming Order',
        route: 'incoming-order-Outlet',
        icon: 'LogInIcon',
      },
      {
        title: 'My Order',
        route: 'outgoing-order-Outlet',
        icon: 'LogOutIcon',
      },
    ],
  },
  {
    title: 'Singapore Order',
    route: 'singapore-order',
    icon: 'FlagIcon',
    children: [
      {
        title: 'Create Order',
        route: 'singapore-order',
        icon: 'BoxIcon',
      },
      {
        title: 'Incoming Order',
        route: 'incoming-order-singapore',
        icon: 'LogInIcon',
      },
      {
        title: 'My Order',
        route: 'outgoing-order-singapore',
        icon: 'LogOutIcon',
      },
    ],
  },
  {
    title: 'Bulk Order',
    route: 'bulk-order',
    icon: 'GridIcon',
  },
  {
    title: 'DHL Order',
    route: 'dhl-order',
    icon: 'TruckIcon',
  },
  {
    title: 'Distributor Order',
    icon: 'ShoppingBagIcon',
    children: [
      {
        title: 'Incoming Orders',
        route: 'distributor-incoming-orders',
        icon: 'BoxIcon',
      },
    ],
  },
  {
    title: 'B2B Order',
    route: 'b2bpro-order',
    icon: 'BoldIcon',
  },
  {
    title: 'FAMA',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Agrofun',
        route: 'Outlet-agrofun',
        icon: 'BoxIcon',
        children: [
          // {
          //   title: "Create Order",
          //   route: "Outlet-order",
          //   icon: "BoxIcon",
          // },
          {
            title: 'Incoming Order',
            route: 'incoming-order-agrofun',
            icon: 'LogInIcon',
          },
          // {
          //   title: "My Order",
          //   route: "outgoing-order-agrofun",
          //   icon: "LogOutIcon",
          // },
        ],
      },
      {
        title: 'Agrobazaar',
        route: 'Outlet-order',
        icon: 'BoxIcon',
        children: [
          // {
          //   title: "Create Order",
          //   route: "Outlet-order",
          //   icon: "BoxIcon",
          // },
          {
            title: 'Incoming Order',
            route: 'incoming-order-agrobazaar',
            icon: 'LogInIcon',
          },
          // {
          //   title: "My Order",
          //   route: "outgoing-order-agrobazaar",
          //   icon: "LogOutIcon",
          // },
        ],
      },
    ],
  },

  // {
  //   title: 'B2B Order',
  //   route: 'b2b-order',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'Incoming Orders',
  //       route: 'b2bpro-incoming-orders',
  //       icon: 'LogInIcon',
  //     },
  //     {
  //       title: 'Accepted Orders',
  //       route: 'b2bpro-accepted-orders',
  //       icon: 'TruckIcon',
  //     },
  //   ],
  // },
  {
    title: 'Route Planning',
    icon: 'MapPinIcon',
    children: [
      {
        title: 'Create Route',
        route: 'map_routing',
        icon: 'MapPinIcon',
        resource: 'CreateRoute',
        action: 'manage',
      },
      {
        title: 'Route List',
        route: 'route-tracker',
        icon: 'CornerRightDownIcon',
        resource: 'RouteTracker',
        action: 'manage',
      },
    ],
  },
  {
    title: 'Track & Trace',
    route: 'track-and-trace',
    icon: 'MapIcon',
  },
  {
    title: 'Postal Coverage',
    route: 'postal-coverage',
    icon: 'PieChartIcon',
  },
  // {
  //   title: 'Transaction',
  //   route: 'transaction',
  //   icon: 'MapPinIcon',
  // },
  {
    title: 'Report',
    route: 'report',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Phone Book',
    route: 'phone-book',
    icon: 'PhoneCallIcon',
  },
  {
    title: 'Log Points',
    route: 'log-points',
    icon: 'SearchIcon',
  },
];
